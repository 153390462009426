@import "~@/styles/variables/variables.scss";























::v-deep {
	.el-input__suffix {
		right: 6px !important;
	}
}
