@import "~@/styles/variables/variables.scss";





























::v-deep {
	.el-form-item__error {
		top: 80%;
		// padding-top: 0;
	}
}
