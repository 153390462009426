@import "~@/styles/variables/variables.scss";





























































































































































































































































































































.page-main-right {
	display: flex;
	flex-direction: column;
	.config-list {
		flex: 1;
		overflow: scroll;
		::v-deep {
			.el-checkbox__label {
				font-size: 14px;
				font-weight: 600;
				color: #222222 !important;
				line-height: 21px;
			}
		}
		.sub-form-item-group {
			margin: 24px 0 22px 14px;
			display: flex;
			flex-wrap: wrap;
			flex-flow: flow;

			::v-deep .el-form-item {
				flex: 0 0 calc(50% - 10px);

				box-sizing: border-box;
				display: flex;
				&:nth-child(2n) {
					margin-left: 20px;
				}
				.el-form-item__content {
					flex: 1;
				}
			}
		}
	}
	.btn-group {
		margin-top: 23px;
		flex: 0 0 40px;
	}
}
.page-main-left {
	display: flex;
	flex-direction: column;

	.device-type-list {
		flex: 1;
		overflow-y: auto;
		@include base-scrollbar(-6px);
		margin-top: 38px;
		.device-type-item {
			padding-left: 13px;
			margin-bottom: 10px;
			height: 40px;
			line-height: 40px;
			background: #f0f0f0;
			border-radius: 8px;
			font-size: 14px;
			color: #666666;
			box-sizing: border-box;
			&.active {
				background: #ebedfa;
				color: #4d6bff;
			}
		}
	}
}
